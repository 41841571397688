@font-face {
    font-family: 'butlerlight';
    src: url('../fonts/butler_light-webfont.eot');
    src: url('../fonts/butler_light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/butler_light-webfont.woff2') format('woff2'),
         url('../fonts/butler_light-webfont.woff') format('woff'),
         url('../fonts/butler_light-webfont.ttf') format('truetype'),
         url('../fonts/butler_light-webfont.svg#butlerlight') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
	font-family: 'Brandon';
	font-style: normal;
	font-weight: 700;
	src: url('../fonts/Brandon_bld.eot');
	src: url('../fonts/Brandon_bld.eot') format('embedded-opentype'),
		 url('../fonts/Brandon_bld.woff2') format('woff2'),
		 url('../fonts/Brandon_bld.woff') format('woff'),
		 url('../fonts/Brandon_bld.ttf') format('truetype'),
		 url('../fonts/Brandon_bld.svg#Brandon_bld') format('svg');
}

@font-face {
    font-family: 'Brandon';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/Brandon_med.eot');
    src: url('../fonts/Brandon_med.eot') format('embedded-opentype'),
         url('../fonts/Brandon_med.woff2') format('woff2'),
         url('../fonts/Brandon_med.woff') format('woff'),
         url('../fonts/Brandon_med.ttf') format('truetype'),
         url('../fonts/Brandon_med.svg#Brandon_med') format('svg');
}

@font-face {
    font-family: 'Brandon';
    font-style: normal;
    font-weight: 100;
    src: url('../fonts/Brandon_light.eot');
    src: url('../fonts/Brandon_light.eot') format('embedded-opentype'),
         url('../fonts/Brandon_light.woff2') format('woff2'),
         url('../fonts/Brandon_light.woff') format('woff'),
         url('../fonts/Brandon_light.ttf') format('truetype'),
         url('../fonts/Brandon_light.svg#Brandon_light') format('svg');
}

@font-face {
    font-family: 'piscines-concept';
    src:    url('../fonts/piscines-concept.eot?7rlutl');
    src:    url('../fonts/piscines-concept.eot?7rlutl#iefix') format('embedded-opentype'),
        url('../fonts/piscines-concept.ttf?7rlutl') format('truetype'),
        url('../fonts/piscines-concept.woff?7rlutl') format('woff'),
        url('../fonts/piscines-concept.svg?7rlutl#piscines-concept') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"], .font-icon {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'piscines-concept' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-brand:before {
    content: "\e91b";
    color: #bd081c;
}
.icon-facebook:before {
    content: "\e907";
}
.icon-arrow:before {
    content: "\e901";
}
.icon-email:before {
    content: "\e902";
}
.icon-telephone:before {
    content: "\e906";
}
.icon-linkedin:before {
    content: "\e921";
}
.icon-instagram:before {
    content: "\e920";
}
.icon-youtube:before {
    content: "\e91f";
}
.icon-twitter:before {
    content: "\e91d";
}
.icon-google-plus:before {
    content: "\e91c";
}
.icon-amenagement-exterieur:before {
    content: "\e900";
}
.icon-amenagement-interieur:before {
    content: "\e903";
}
.icon-architecte:before {
    content: "\e904";
}
.icon-bein-etre:before {
    content: "\e905";
}
.icon-couloir:before {
    content: "\e90b";
}
.icon-debordement:before {
    content: "\e90c";
}
.icon-fond-mobile:before {
    content: "\e90d";
}
.icon-forme-libre:before {
    content: "\e90e";
}
.icon-garden:before {
    content: "\e90f";
}
.icon-interieur-exterieur:before {
    content: "\e910";
}
.icon-interieur:before {
    content: "\e911";
}
.icon-jacuzzi:before {
    content: "\e912";
}
.icon-miroir:before {
    content: "\e913";
}
.icon-paroi-verre:before {
    content: "\e914";
}
.icon-paysagee:before {
    content: "\e915";
}
.icon-pergolas:before {
    content: "\e916";
}
.icon-piscine:before {
    content: "\e917";
}
.icon-plage-immergee:before {
    content: "\e918";
}
.icon-semi-enterre:before {
    content: "\e919";
}
.icon-toit-terrasse:before {
    content: "\e91a";
}
.icon-download:before {
    content: "\e90a";
}
.icon-euro_symbol:before {
    content: "\e91e";
}
.icon-zoom_out_map:before {
    content: "\e908";
}
.icon-arrow-up:before {
    content: "\e909";
}
