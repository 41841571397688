html {
	box-sizing: border-box;
}

*, *:before, *:after {
	box-sizing: inherit;
	outline: none;
}

a, a:hover, a:focus, a:active, a:visited {
	outline: none;
}

html {
	text-size-adjust: 100%;
}

body {
	background-color: $color-bg;
	
	@include font-size(14);
	font-family: $font-base;
	color: $color-text;
	
	&.noscroll {
		overflow: hidden;
	}
}

/* 
 ######    ########   ########   ##     ##   ######    ########   ##     ##  ########   ########   
##    ##      ##      ##     ##  ##     ##  ##    ##      ##      ##     ##  ##     ##  ##         
##            ##      ##     ##  ##     ##  ##            ##      ##     ##  ##     ##  ##         
 ######       ##      ########   ##     ##  ##            ##      ##     ##  ########   ######     
      ##      ##      ##   ##    ##     ##  ##            ##      ##     ##  ##   ##    ##         
##    ##      ##      ##    ##   ##     ##  ##    ##      ##      ##     ##  ##    ##   ##         
 ######       ##      ##     ##   #######    ######       ##       #######   ##     ##  ########   
 */

.wrapper{
	width: 100%;
}

.container {
	padding: 0 0 40px 0;

	@include mq($from: tablet) {
		padding: 0 0 75px 0;
	}

	@include mq($from: desktop) {
		padding: 0 0 150px 0;
	}
}

.inner {
	@include clearfix;
	margin: 0 auto;
	position: relative;

	@include mq($until: tablet) {
		&.inner--half{
			&.inner--half--right-bg::after,
			&.inner--half--left-bg::after {
				display: block;
				content: "";
				padding-top: 50%;
			}
		}
	}

	&.inner--half {
		&.inner--half--theme-1-2 {
			&::before { background-color: $color-1; }
			&::after  { background-color: $color-2; }
		}

		&.inner--half--theme-2 {
			&::before { background-color: $color-2; }
		}
	}

	@include mq($from: tablet) {
		&.inner--half {
			&::after, &::before {
				content: "";
				display: block;
				position: absolute;
				top: 0;
				bottom: 0;
				z-index: -1;
			}

			&::before{
				left: 0;
				right: 50%;
			}

			&::after{
				left: 50%;
				right: 0;
			}

			&.inner--half--left-bg {
				&::before{
					left: 50%;
					right: 0;
				}

				&::after{
					left: 0;
					right: 50%;
				}
			}
		}
	}

	&.inner--half--right-bg::after,
	&.inner--half--left-bg::after {
		background: darken($color-bg, 5%) center center no-repeat;
		background-size: cover;
	}

	// SPECIFIC IMAGES
	&.image-bg--home-1::after               { background-image: url( '#{$image-path}showroom.jpg' ); }
	&.image-bg--about-1::after               { background-image: url( '#{$image-path}showroom-carrelage.jpg' ); }
	&.image-bg--about-2::after               { background-image: url( '#{$image-path}bureau-showroom-piscine-concept.jpg' ); background-position: 20% center; }
	&.image-bg--about-3::after               { background-image: url( '#{$image-path}showroom-interieur-piscine-concept.jpg' ); }
	&.image-bg--projet-avant-projet-1::after { background-image: url( '#{$image-path}esquisse-avant-projet-piscine-concept.jpg' ); }
	&.image-bg--projet-avant-projet-2::after { background-image: url( '#{$image-path}avant-projet-piscine-concept.jpg' ); }
	&.image-bg--projet-piscine-1::after      { background-image: url( '#{$image-path}creation-piscine-concept.jpg' ); }
	&.image-bg--projet-piscine-2::after      { background-image: url( '#{$image-path}renovation-piscine-concept.jpg' ); }
	&.image-bg--projet-amenagement-1::after  { background-image: url( '#{$image-path}amenagement-exterieur-piscine-concept.jpg' ); }
	&.image-bg--projet-amenagement-2::after  { background-image: url( '#{$image-path}partenariat-piscine-concept.jpg' ); }
	&.image-bg--projet-accessoire-1::after  { background-image: url( '#{$image-path}accessoire-entretien-piscine-concept.jpg' ); }
	&.image-bg--projet-accessoire-2::after  { background-image: url( '#{$image-path}accessoire-animation-piscine-concept.jpg' ); }
	&.image-bg--projet-pergolas-1::after     { background-image: url( '#{$image-path}pergolas-gibus-piscine-concept.jpg' ); }
	&.image-bg--projet-pergolas-2::after     { background-image: url( '#{$image-path}pergolas-laterale-gibus-piscine-concept.jpg' ); }
	&.image-bg--projet-mobilier-1::after     { background-image: url( '#{$image-path}mobilier-piscine-concept.jpg' ); }
	&.image-bg--projet-mobilier-2::after     { background-image: url( '#{$image-path}partenaires/visuel-talenti-2.jpg' ); }
	&.image-bg--projet-sav-1::after          { background-image: url( '#{$image-path}entretien-piscine-concept.jpg' ); }
	&.image-bg--projet-sav-2::after          { background-image: url( '#{$image-path}entretien-2-piscine-concept.jpg' ); }
	&.image-bg--contact-1::after             { background-image: url( '#{$image-path}image-contact.jpg' ); }
}

.image-reveal{
	
	position: absolute;
	overflow: hidden;

	@include mq($until: tablet) {
		bottom:0;
		left:0;
		right:0;
		.image-reveal--adapt{
			display: block;
			content: "";
			padding-top: 50%;
		}
	}

	@include mq($from: tablet) {
		bottom:0;
		left:50%;
		right:0;
		top:0;
		z-index:3;
		.inner--half--left-bg &{
			left:0;
			right:50%;
		}
		&.image-reveal--full{
			left:0;
		}
	}

	.image-reveal--white, 
	.image-reveal--color{
	    position: absolute;
	    z-index: 2;
	    transform-origin: right;
	}

	.image-reveal--color{
	    top: 0;
	    right: 0;
	    left: 0;
	    height:34%;
	    background-color: $color-1;
	    &:nth-of-type(2){
	    	top:percentage(1/3);
	    }
	    &:nth-of-type(3){
	    	top:percentage(2/3);
	    }

		.reverse--from-desktop &{
			background-color: $white;
		}

	}

	.image-reveal--white{
	    display: block;
	    top: -1px;
	    right: -1px;
	    bottom: -1px;
	    left: -1px;
	    background-color: darken($color-bg, 5%);
	    background-color: transparent;
	}

}

.inner-content {
	@include clearfix;
	position: relative;
	max-width: 1600px;
	margin: 0 auto;

	@include mq($from: tablet) {
		padding: 0 $grid-gutter-tablet*2;
	}

	@include mq($from: desktop) {
		padding: 0 $grid-gutter-desktop*2;
	}

	@include mq($from: wide) {
		padding: 0 $grid-gutter-wide*2;
	}

	&.inner-content--margin {
		margin: 40px auto;

		@include mq($from: tablet) {
			margin: 90px auto 40px auto;
		}

		@include mq($from: desktop) {
			margin: 150px auto 0 auto;
		}
	}
}

.section--col {
	@include clearfix;
	position: relative;
	padding: 40px 20px;
	
	@include mq($from: tablet) {
		padding-top: 75px;
		padding-bottom: 75px;
		padding-right: 40px;
		width: 50%;
		float: left;

		.inner--half--left-bg &:not(.section--col--onleft) {
			float: right;
			padding-left: 40px;
			padding-right: 0;
		}
	}

	@include mq($from: wide) {
		padding-right: 80px;
		padding-top: 150px;
		padding-bottom: 150px;

		.inner--half--left-bg &:not(.section--col--onleft) {
			padding-left: 80px;
			padding-right: 0;
		}
	}

	&.section--col--extra-padding {
		@include mq($from: tablet) {
			padding-top: 80px
		}

		@include mq($from: desktop) {
			padding-top: 120px;
		}

		@include mq($from: wide) {
			padding-top: 200px;
		}
	}

	&.section--col--small-padding {
		@include mq($from: tablet) {
			padding-top: 40px;
			padding-bottom: 40px;
		}

		@include mq($from: desktop) {
			padding-top: 60px;
			padding-bottom: 60px;
		}

		@include mq($from: wide) {
			padding-top: 80px;
			padding-bottom: 80px;
		}
	}

	.center {
		@include center();
	}
}

.theme-color-1{
	background-color: $color-1;
	color:$white;
}

.theme-color-2{
	background-color: $color-2;
}

.img-responsive{
	max-width: 100%;
	height:auto;
}

.padding-box {
	padding: 0 10px;
}

.google-map {
	display: block;
	position: relative;
	height: 200px;
	z-index: 2;

	@include mq($from: tablet) {
		position: absolute;
		height: auto;
		top: 0;
		left: 0;
		right: 50%;
		bottom: 0;
	}
}


.page-switcher{
	transition: transform .6s ease-in-out, opacity 0.6s ease-in-out;
	position: fixed;
	z-index:50;
	width:percentage(1/10);
	bottom:0;
	top:0;
	background-color: $color-1;
	transform:translate(-50%,-50%) scale(0);
	opacity: 0;

	.loading &{
		opacity: 1;	
		transform:translate(-50%,-50%) scale(15);
	}
}

@for $i from 0 through 4 {
  .page-switcher-#{$i} { 
		transition-delay:.2s * $i;
		left : 20% * $i;
  }
}

// @-webkit-keyframes bubble {
//     from { background-color: #fff; }
//     50% { background-color:red; }
//     to { background-color: #fff; }
// }

@include keyframes(bubble) {
	from { transform:translate(0,-100%) scale(0); opacity:0; }
	50% { transform:translate(0,-100%) scale(1); opacity:1; }
	to { transform:translate(0,-100%) scale(0); opacity:0; }
}

@include keyframes(bubble_two) {
	from { transform:translate(-100%,0) scale(0); opacity:0; }
	80% { transform:translate(-100%,0) scale(1); opacity:1; }
	to { transform:translate(-100%,0) scale(0); opacity:0; }
}

.page-switcher-bubble{

	transition: transform 0.8s $ease, opacity 0.8s $ease;
	position: fixed;
	z-index:51;
	top:50%;
	left:50%;
	width:26px;
	height:26px;
	background-color: #fff;
	border:2px solid darken($color-1,10%);
	border-radius:50%;
	opacity:0;

	&.page-switcher-bubble--two{
		width:16px;
		height:16px;
	}

	.loading &{
		animation: bubble 2s infinite .8s;
		&.page-switcher-bubble--two{
			animation: bubble_two 2s infinite;
		}
	}

	.touch &{
		display: none;
	}

}

/* 
########    #######   ########   ##     ##   ######    
##         ##     ##  ##     ##  ###   ###  ##    ##   
##         ##     ##  ##     ##  #### ####  ##         
######     ##     ##  ########   ## ### ##   ######    
##         ##     ##  ##   ##    ##     ##        ##   
##         ##     ##  ##    ##   ##     ##  ##    ##   
##          #######   ##     ##  ##     ##   ######    
*/

.form-return {
	display: block;
	position: absolute;
	opacity: 0;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	transform: translate(-100px, 0);

	transition:
		transform 0.3s ease-out 0.3s,
		opacity 0.3s ease-out 0.3s;

	&.sending {
		opacity: 1;
		transform: translate(0, 0);
	}

	span {
		@include center();
		padding: 20px;

		border: 1px solid $color-1;

		p {
			margin: 0;
			color: $color-1;
		}
	}
}

.contact-form {
	opacity: 1;
	transform: translate(0, 0);

	transition:
		transform 0.3s ease-out,
		opacity 0.3s ease-out;

	&.sending {
		opacity: 0;
		transform: translate(100px, 0);
	}
}

.form__container {
	display: block;
	margin-bottom: 10px;

	&.form__input {
		border: 1px solid $color-1;
		border-color: rgba($color-1, 0.6);

		transition:
			border-color 0.3s ease-out,
			background-color 0.3s ease-out;

		&.current {
			border-color: $color-1;	
		}
	}

	.form__checkbox {
		margin-right: 10px;
	}

	&.form__select {
		select {
			width: 90%;
			margin: 2px 10px 10px 10px
		}
	}

	&.error {
		border-color: $color-warning !important;
		background-color: rgba($color-warning, 0.05);
	}
}

.form__label {
	display: block;
	padding: 5px 10px 2px 10px;
	@include font-size(10);

	.error & {
		color: $color-warning;
	}

	&.form__label--checkbox {
		padding: 15px 10px;
		@include font-size(12);

		a {
			color: $color-link;
			border-bottom: 1px dotted $color-link;

			transition: color 0.2s ease-in-out, border-color 0.2s ease-in-out;

			.no-touch & {
				&:hover, &:focus {
					color: $color-text;
					border-color: $color-text;
				}
			}
		}
	}
}

.form__field {
	@include input_mobile();
	display: block;
	width: 100%;
	padding: 2px 10px 10px 10px;

	background: transparent;
	border: none;

	color: $color-text;
	font-family: $font-base;
}

textarea.form__field {
	height: 100px;

	@include mq($from: desktop) {
		height: 200px;
	}
}

/* 
########   ##    ##   ########    #######   
   ##       ##  ##    ##     ##  ##     ##  
   ##        ####     ##     ##  ##     ##  
   ##         ##      ########   ##     ##  
   ##         ##      ##         ##     ##  
   ##         ##      ##         ##     ##  
   ##         ##      ##          #######   
 */


p, p:last-of-type{
	line-height: 1.9;
	margin-bottom: 1.5em;	
}

[data-link]{
	.no-touch &:hover, .no-touch &:focus, .touch &:active {
		cursor: pointer;
	}
}

.title-lev1 {
	font-family: $font-title;
	@include font-size(28);
	line-height: 1em;
	margin-bottom:20px;

	@include mq($from: tablet) {
		@include font-size(40);
	}

	@include mq($from: desktop) {
		@include font-size(45);
		margin-bottom:60px;
	}

	&.title-lev1--right {
		@include mq($from: tablet) {
		 	text-align: right;	
		}
	}
}

.title-lev2 {
	font-family: $font-alt;
	@include font-size(16);
	line-height: 1.5em;
	margin-bottom:20px;
	text-transform: uppercase;

	a{
		color:$color-1;
		border-bottom: 1px solid $color-1;
	}
}

.title-lev3 {
	font-family: $font-title;
	@include font-size(28);
	line-height: 1em !important;
	margin-bottom:20px !important;
	@include mq($from: tablet) {
		@include font-size(34);
		margin-bottom:60px !important;
	}
}

.title-lev4 {
	font-family: $font-title;
	@include font-size(30);
	line-height: .9em;
	margin-bottom:20px;
	@include mq($from: desktop) {
		@include font-size(35);
	}
	@include mq($from: wide) {
		@include font-size(45);
	}
	a { color:$black; }
}

.title-lev5 {
	font-family: $font-title;
	@include font-size(30);
	line-height: 1em;
	margin-bottom:20px;
	// @include mq($from: desktop) {
	// 	@include font-size(35);
	// }
	// @include mq($from: wide) {
	// 	@include font-size(45);
	// }
	a { color: $black; }
}

strong{
	font-weight: 700;
}

.editor-text {
	color: $color-text;

	p {
		line-height: 1.8;
		margin-bottom: 1.5em;	
	}

	a {
		display: inline-block;
		position: relative;
		
		border-bottom: 1px solid $color-link;
		border-bottom-color: rgba($color-link, 0.3);
		
		color: $color-link;

		transition:
			color 0.2s ease-in-out,
			border-color 0.2s ease-in-out;
		
		&::after {
			display: block;
			content: "";
			height: 1px;
			width: 0;
			position: absolute;
			top: 100%;
			left: 0;

			background-color: $color-link-hover;

			transition: width 0.5s ease-out;
		}

		.no-touch & {
			&:hover, &:focus {
				color: $color-link-hover;
				border-color: lighten($color-link, 20%);

				&::after {
					width: 100%;
				}
			}
		}
	}

	h1 {
		@extend .title-lev1;
	}

	h2 {
		margin-bottom: 1.5em;
		font-family: $font-alt;
		@include font-size(16);
	}

	h3 {
		margin-bottom: 1em;
		font-weight: 600;
	}

	h4 {
		font-family: $font-title;
		margin-bottom: 1.5em;
		font-weight: 400;
		line-height: .9em;
		@include font-size(30);
		@include mq($from: tablet) {
			@include font-size(45);
		}
	}

	img {
		max-width: 100%;
		height: auto;

		&.top-image {
			margin-bottom: 20px;

			@include mq($from: desktop) {
				max-width: 75%;
				margin-bottom: 40px;
			}
		}
	}

	ul{
		margin-bottom:2em;
	}

	li {
		margin-bottom: 10px;
		margin-left: 20px;
		list-style-type: circle;
	}
}

.projet__logo-partner {
	display: block;
	width: 200px;
	margin-bottom: 20px;
	height: auto;
}

/* 
########   ########   ########      ###     ########    ######    ########   ##     ##  ##     ##  ########   
##     ##  ##     ##  ##           ## ##    ##     ##  ##    ##   ##     ##  ##     ##  ###   ###  ##     ##  
##     ##  ##     ##  ##          ##   ##   ##     ##  ##         ##     ##  ##     ##  #### ####  ##     ##  
########   ########   ######     ##     ##  ##     ##  ##         ########   ##     ##  ## ### ##  ########   
##     ##  ##   ##    ##         #########  ##     ##  ##         ##   ##    ##     ##  ##     ##  ##     ##  
##     ##  ##    ##   ##         ##     ##  ##     ##  ##    ##   ##    ##   ##     ##  ##     ##  ##     ##  
########   ##     ##  ########   ##     ##  ########    ######    ##     ##   #######   ##     ##  ########   
*/

.nav-breadcrumb {
	padding: 20px 0;
	position: relative;

	@include mq($until: tablet) {
		margin-left: 20px;
	}

	@include mq($from: tablet) {
		margin-bottom: 30px;
		float: left;
	}

	@include mq($from: desktop) {
		margin-bottom: 40px;
	}

	@include mq($from: wide) {
		margin-bottom: 60px;
	}

	li {
		display: block;
		position: relative;

		padding: 0 15px;
	
		font-family: $font-base;
		@include font-size(14);
		color: $color-1;

		&:before {
			content: "";
			display: block;
			width: 4px;
			height: 4px;
			position: absolute;
			top: 8px;
			left: 0;

			background-color: $color-1;
			border-radius: 50%;
		}

		
		@include mq($from: tablet) {
			display: inline-block;

			&:first-of-type {
				padding: 0 15px 0 0;
				
				&:before {
					display: none;
				}
			}
		}

		a {			
			color: $color-1;
			position: relative;

			transition: color 0.2s ease-in-out;

			@include mq($from: tablet) {
				padding: 0 0 5px 0;
				
				&:after {
					content: "";
					display: block;
					width: 0;
					height: 1px;
					position: absolute;
					top: 100%;
					left: 0;

					background-color: $color-link-hover;

					transition: width 0.2s ease-in-out;
				}
			}
			
			.no-touch & {
				&:hover, &:focus {
					color: $color-link-hover;

					&:after {
						width: 100%;
					}
				}
			}
			
			.touch & {
				&:active {
					color: $color-2;

					&:after {
						width: 100%;
					}
				}
			}
		}

	}
}

/* 
########   ####  ##         ########   ########   ########    ######    
##          ##   ##            ##      ##         ##     ##  ##    ##   
##          ##   ##            ##      ##         ##     ##  ##         
######      ##   ##            ##      ######     ########    ######    
##          ##   ##            ##      ##         ##   ##          ##   
##          ##   ##            ##      ##         ##    ##   ##    ##   
##         ####  ########      ##      ########   ##     ##   ######    
*/

.nav-filter {
	@include clearfix;

	@include mq($until: tablet) {
		padding: 0 0 20px 20px;
		float: left;
	}

	@include mq($from: tablet) {
		// position: absolute;
		// top: 10px;
		// right: 40px;
		float: right;
		padding: 10px 0 0 0;
	}

	.select-style {
		width: 200px;
		
		@include mq($until: tablet) {
			padding-top: 10px;
		}

		@include mq($from: tablet) {
			float: left;
		}
	}
}

.nav-filter__label {
	@include mq($until: tablet) {
		display: none;
	}
	
	@include mq($from: tablet) {
		display: inline-block;
		padding: 10px 20px 10px 0;
		float: left;
	}
}

/* 
##     ##  ########      ###     ########   ########   ########   
##     ##  ##           ## ##    ##     ##  ##         ##     ##  
##     ##  ##          ##   ##   ##     ##  ##         ##     ##  
#########  ######     ##     ##  ##     ##  ######     ########   
##     ##  ##         #########  ##     ##  ##         ##   ##    
##     ##  ##         ##     ##  ##     ##  ##         ##    ##   
##     ##  ########   ##     ##  ########   ########   ##     ##  
*/

.overlay-content{
	transition: visibility 0.5s ease-out, opacity 0.5s ease-out;
	position: fixed;
	top:0;
	left:0;
	bottom:0;
	right:0;
	background-color: rgba($white, 0.9);
	z-index: 10;
	visibility: hidden;
	opacity: 0;
	
	&.open {
		visibility: visible;
		opacity: 1;
		transition: visibility 0.6s ease-out, opacity 0.6s ease-out;
	}
}

.header {
	transition: background-color 0.2s ease-in-out;
	z-index: 10;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 10;
	background-color: $white;

	@include mq($from: tablet) {
		background-color: transparent;
	}

	// &.stuck {
	// 	background-color: $white;
	// 	background-color: rgba($white,.8);
	// }
}

.go-up {
	display: block;
	width: 60px;
	height: 60px;
	overflow: hidden;
	z-index: 10;
	position: absolute;
	top: 0;
	right: 0;

	background-color: $color-1;
	
	transform: translate(60px, 0);
	transition:
		background-color 0.3s ease-out,
		transform 0.3s ease-out;

	@include mq($from: tablet) {
		z-index: 30;
	}

	.stuck & {
		transform: translate(0, 0);
	}

	&:before {
		display: block;
		content: "";
		width: 1px;
		height: 100px;
		position: absolute;
		left: 50%;
		top: 0;
		z-index: 31;

		background-color: $white;

		transform: translate(0, 100px);

		transition:
			height 0.3s ease-out,
			transform 0.3s ease-out;

	}

	&:after{
		@extend %icon;
		content: "\e901";

		display: block;
		width: 60px;
		height: 60px;
		line-height: 60px;
		

		@include font-size(16);
		color: $white;
		text-align: center;
		
		transform: translate(0, 0) rotate(-90deg);
		transition: transform 0.3s ease-out;

		.stuck & {
			right: 0;
		}
	}

	.no-touch &:hover, .touch &:active {
		background-color: lighten($color-1, 10%);
		
		&:before {
			height: 0;
			transform: translate(0, 0);
		}

		&:after {
			transform: translate(0, -5px) rotate(-90deg);
		}
	}
}

.nav-toggle {
	width: 60px;
	height: 60px;
	position: relative;
	float: left;
	z-index: 12;
	
	background-color: $color-1;

	cursor: pointer;
	transition:
		background-color 0.3s ease-out,
		width 0.3s ease-out,
		height 0.3s ease-out;
	
	@include mq($from: tablet) {
		width: 120px;
		height: 120px;

		.stuck & {
			width: 60px;
			height: 60px;
		}
	}

	.no-touch &:hover, .no-touch &:focus, .touch &:active {
		background-color: lighten($color-1, 10%);
	}
}

.nav-icon {
	transition: top 0.2s ease-in-out;
	position: absolute;
	width:24px;
	height:14px;
	top:50%;
	left:50%;
	margin:-7px 0 0 -12px;
	cursor: pointer;
	z-index:11;

	&.open{
		span { background-color: $color-2; }
	}
}

.nav-icon span {
  display: block;
  position: absolute;
  height: 1px;
  width: 50%;
  background: $white;
  opacity: 1;
  transform: rotate(0deg);
  transition: .25s ease-in;
}

.nav-icon span:nth-child(even) {
  left: 50%;
}

.nav-icon span:nth-child(odd) {
  left:0px;
}

.nav-icon span:nth-child(1), .nav-icon span:nth-child(2) {
  top: 0px;
}

.nav-icon span:nth-child(3), .nav-icon span:nth-child(4) {
  top: 6px;
}

.nav-icon span:nth-child(5), .nav-icon span:nth-child(6) {
  top: 12px;
}

.nav-icon.open span:nth-child(1),.nav-icon.open span:nth-child(6) {
  transform: rotate(45deg);
}

.nav-icon.open span:nth-child(2),.nav-icon.open span:nth-child(5) {
  transform: rotate(-45deg);
}

.nav-icon.open span:nth-child(1) {
  left: 5px;
  top: 4px;
}

.nav-icon.open span:nth-child(2) {
  left: calc(50% - 4px);
  top: 4px;
}

.nav-icon.open span:nth-child(3) {
  left: -50%;
  opacity: 0;
}

.nav-icon.open span:nth-child(4) {
  left: 100%;
  opacity: 0;
}

.nav-icon.open span:nth-child(5) {
  left: 5px;
  top: 7px;
}

.nav-icon.open span:nth-child(6) {
  left: calc(50% - 4px);
  top: 7px;
}

.logo {
	display: block;
	width: calc(100% - 120px);
	// width: 109px;
	height: 60px;
	float: left;

	background: $white url('#{$image-path}piscines-concept.png') center center no-repeat;
	background-size: 69px auto;
	
	transition:
		width 0.3s ease-out,
		height 0.3s ease-out,
		background-size 0.3s ease-out,
		background-position 0.3s ease-out,
		background-color 0.3s ease-out;
	
	@include retina {
		background-image: url('#{$image-path}piscines-concept@2x.png');
	}

	@include mq($from: tablet) {
		background-position: 40px center;
		width: 210px;
		height: 120px;
		background-size: 130px auto;

		.stuck & {
			width: 109px;
			height: 60px;
			background-size: 69px auto;
			background-position: 20px center;
		}

	}

	.no-touch &:hover,
	.no-touchevents &:hover,
	.no-touch &:focus,
	.no-touchevents &:focus,
	.touch &:active,
	.touchevents &:active {
		background-color: rgba($color-bg, 0.85);
	}

}

.header-contact{
	clear:both;

	a{
		transition: background-color 0.3s ease-in-out;
		display:block;
		line-height: 30px;
		text-align: center;
		color:$white;
		float:left;
		width:50%;
		padding:10px 0;

		&.header-contact-phone{
			background-color: $color-3;
			.no-touch &:hover, .no-touch &:focus, .touch &:active {
				background-color: lighten($color-3,10%);
			}
		}
		&.header-contact-devis{
			text-transform: uppercase;
			background-color: $color-1;
			font-family: $font-alt;
			.no-touch &:hover, .no-touch &:focus, .touch &:active {
				background-color: lighten($color-1,10%);
			}
		}
	}
	
	@include mq($from: tablet) {
		transition: transform 0.3s ease-in-out;
		position: fixed;
		top:0;
		right:0;
		clear:none;
		transform:translate(0,0);

		a{
			position: relative;
			z-index:1;
			transition: line-height 0.3s ease-in-out, background-color 0.3s ease-in-out, width 0.3s ease-in-out, height 0.3s ease-in-out;
			line-height: 60px;
			height:60px;
			width:auto;
			padding:0 20px;
			float:left;
			display: inline-block;

			&.header-contact-phone{
				background-color: $color-1;
				.no-touch &:hover, .no-touch &:focus, .touch &:active {
					background-color: lighten($color-1,10%);
				}
			}

			div{
				transition: transform 0.3s ease-in-out, width 0.3s ease-in-out, height 0.3s ease-in-out, line-height 0.3s ease-in-out;
				white-space:nowrap;
				transform:translate(0,0);
				text-align: center;
				color: $white;
			}

			&:after{
				content:"";
				position: absolute;
				top:20px;
				bottom:20px;
				right:0;
				width:1px;
				background-color: lighten($color-1,10%);
			}

			&:last-of-type:after{
				display: none;
			}

		}

		.stuck & {
			transform:translate(-60px,0);

			a:last-of-type:after{
				display: block;
			}
		}

	}

	@include mq($from: desktop) {
		@include font-size(16);
	}

}

.nav-main {
	clear: both;
	background-color: $color-1;
	z-index: 30;

	.nav-main__content{
		visibility: hidden;
		overflow: hidden;
	}

	a {
		position: relative;
		display: inline-block;
		color: $white;
		font-family: $font-title;
		opacity: 0.85;

		-webkit-font-smoothing: antialiased;
   		-moz-osx-font-smoothing: grayscale;

   		&:after {
   			display: block;
   			content: "";
   			width: 500px;
   			height: 1px;
   			position: absolute;
   			top: 55%;
   			left: 0;
   			opacity: 0;


   			background-color: $white;
			
			transform: translate(-50%, 0);
			
			transition:
   					left 0.2s ease-in,
					width 0.2s ease-in,
					transform 0.2s ease-in,
   					opacity 0.2s ease-in;
   		}

   		.no-touch &:hover,
   		.no-touchevents &:hover,
   		.no-touch &:focus,
   		.no-touchevents &:focus,
   		.touch &:active,
   		.touchevents &:active,
   		&.active {
   			opacity: 1;

   			&:after {
   				width: 60px;
				left: 100%;
   				opacity: 1;
   				transform: translate(0, 0);
   				
   				transition:
   					left 0.4s ease-out,
   					width 0.4s ease-out,
   					transform 0.4s ease-out,
   					opacity 0.3s ease-out;
   			}
   		}

		// &:after {
		// 	display: block;
		// 	content: "";
		// 	width: 0;
		// 	position: absolute;
		// 	top: 0;
		// 	left: -150px;
		// 	bottom: 0;
		// 	z-index: -1;

		// 	background-color: lighten($color-1,5%);
			
		// 	transition: width 0.2s ease-in-out;
		// }

		// .no-touch &:hover, .no-touch &:focus, .touch &:active, &.active {
		// 	&:after { width: calc(100% + 150px); }
		// }

	}

	.nav-main__subnav{
		display: none;

		// a {
		// 	font-family: $font-alt;
		// 	text-transform: uppercase;
		// 	font-weight: 400;
		// 	&:before{
		// 		content:"";
		// 		display: inline-block;
		// 		width:20px;
		// 		height:1px;
		// 		margin-right:5px;
		// 		background-color: #fff;
		// 		transform:translateY(-4px);
		// 	}
		// }
	}

	.nav-main__menu > li {
		transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
		transform: translateX(-150px);
		opacity: 0;
		visibility: hidden;
	}

	&.open {
		.nav-main__menu > li {
			animation: animatemenu .3s ease-out .4s;
			animation-fill-mode: forwards;
			visibility: visible;

			&:nth-of-type(2) { animation-delay: .5s; }
			&:nth-of-type(3) { animation-delay: .6s; }
			&:nth-of-type(4) { animation-delay: .7s; }
			&:nth-of-type(5) { animation-delay: .8s; }
			&:nth-of-type(6) { animation-delay: .9s; }

			@include mq($until: tablet) {
				margin-top: 20px;
			}

			@include mq($from: tablet) {
				margin-bottom: 20px;
				
				@media screen and (orientation:landscape) and (max-height: 768px) {
					margin-bottom: 10px;
				}
			}
		}
	}

	@include mq($until: tablet) {
		transition: width 0.5s ease-in-out;
		position: fixed;
		top: 0;
		left: 0;
		bottom: 0;
		width: 0;
		z-index: 11;

		&.open {
			width: 100%;
		}

		.nav-main__content {
			padding: 80px 20px 20px 26px;
		}

		a {
			// padding:5px 0;
			padding: 5px 20px 5px 0;
			@include font-size(22);
			span {
				@include font-size(14);
			}
		}

		// .nav-main__subnav{
		// 	margin-left:30px;
		// 	a{
		// 		@include font-size(14);
		// 	}
		// }

	}

	@include mq($from: tablet) {
		width: 0;
		position: fixed;
		top: 120px;
		margin-left: 120px;
		// z-index: -1;
		bottom: 0;
		opacity: 0;

		transition:
			width 0.5s ease-out,
			top 0.3s ease-out,
			margin-left 0.3s ease-out,
			opacity 0.1s ease-out 0.5s;

		.stuck & {
			top: 60px;
			margin-left: 60px;
		}

		&.open {
			width: 760px;
			opacity: 1;

			transition:
				width 0.5s ease-out,
				top 0.2s ease-out,
				left 0.2s ease-out,
				opacity 0s ease-out;
		}

		.nav-main__content {
			padding: 90px 60px 20px 60px;
			visibility: hidden;
		}

		a {
			padding: 5px 20px 5px 0;
			@include font-size(48);

			span {
				@include font-size(26);
			}
		}

		// .nav-main__subnav{
		// 	margin-left: 50px;
		// 	a {
		// 		@include font-size(16);
		// 	}
		// }
	}

	@media screen and (orientation:landscape) and (max-height: 768px) {
		.nav-main__content {
			padding: 60px 60px 20px 60px;
		}

	    a {
	    	@include font-size(36);
			
			span {
				@include font-size(18);
			}
	    }
	}
}

@keyframes animatemenu {
	from {
		transform: translateX(-150px);
		opacity: 0;
	}
	to {
		transform: translateX(0px);
		opacity: 1;
	}
}

/* 
##     ##     ###     ####  ##    ##   
###   ###    ## ##     ##   ###   ##   
#### ####   ##   ##    ##   ####  ##   
## ### ##  ##     ##   ##   ## ## ##   
##     ##  #########   ##   ##  ####   
##     ##  ##     ##   ##   ##   ###   
##     ##  ##     ##  ####  ##    ##   
*/

// .fotorama{
// 	background:red;
// 	.slider__img {
// 		@include mq($until: tablet) {
// 			height: 20vh;
// 			min-height: 150px;
// 		}
// 		@include mq($from: tablet) {
// 			height: 50vh;
// 			max-height: 400px;
// 		}
// 	}
// }


.slider-header {
	position: relative;

	.home & {
		height: 33vh;
		min-height: 250px;
		background-position: center top;
	}

	@include mq($from: tablet) {
		.home & {
			height: 75vh;
			max-height: none;
			background-position: center top;
		}
	}

	.slider__img {

		@include mq($until: tablet) {
			height: 20vh;
			min-height: 150px;
			background: transparent url('#{$image-path}slider/home-min.jpg') center center no-repeat;
			background-size: cover;

			&:after{
				content:"";
				display: block;
				padding-top: 100%;
			}

			.home & {
				height: 33vh;
				min-height: 250px;
				background-position: center top;
			}

			.about &              { background-image: url('#{$image-path}slider/banner-about-min.jpg'); }
			body[class^="projet"] & { background-image: url('#{$image-path}slider/banner-projet-min.jpg'); }
			.realisation &        { background-image: url('#{$image-path}slider/banner-realisation-min.jpg'); }
			.realisation-detail & { background-image: url('#{$image-path}slider/banner-realisation-min.jpg'); }
			.actualite &          { background-image: url('#{$image-path}slider/banner-actualite-min.jpg'); }
			.actualite-detail &   { background-image: url('#{$image-path}slider/banner-actualite-min.jpg'); }
			.contact &            { background-image: url('#{$image-path}slider/banner-contact-min.jpg'); }

		}
		
		@include mq($from: tablet) {
			height: 50vh;
			max-height: 400px;

			background-image: url('#{$image-path}slider/home-tablet.jpg');
			background-size: cover;
			background-position: center center;

			.home & {
				height: 75vh;
				max-height: none;
				background-position: center top;
			}

			.about &              { background-image: url('#{$image-path}slider/banner-about.jpg'); }
			body[class^="projet"] &  { background-image: url('#{$image-path}slider/banner-projet.jpg'); }
			.realisation &        { background-image: url('#{$image-path}slider/banner-realisation.jpg'); }
			.realisation-detail & { background-image: url('#{$image-path}slider/banner-realisation.jpg'); }
			.actualite &          { background-image: url('#{$image-path}slider/banner-actualite.jpg'); }
			.actualite-detail &   { background-image: url('#{$image-path}slider/banner-actualite.jpg'); }
			.contact &            { background-image: url('#{$image-path}slider/banner-contact.jpg'); }
		}
	}
}

.slider__text{
	width: 100%;
	padding: 40px;
	position: absolute;
	left: 0;
	bottom: 0;
	z-index:5;
	
	@include font-size(20);
	color: $white;
	text-align: right;
	font-family: $font-title;
	line-height: 1em;

	@include mq($from: tablet) {
		@include font-size(60);
	}

	@include mq($from: desktop) {
		@include font-size(66);
		width: 900px;
		left: 50%;
		margin-left: -450px;
	}

	@include mq($from: wide) {
		width:1200px;
		margin-left:-600px;
		padding-bottom:120px;
	}

	body:not(.home) &{
		padding-right:80px;
		@include font-size(30);
		&:after{
			content:"";
			position: absolute;
			top:60px;
			right:40px;
			display:inline-block;
			height:1px;
			width:30px;
			background-color: #fff;
		}

		@include mq($from: tablet) {
			@include font-size(60);
			padding-right:100px;
			&:after{ width:44px; top:80px; }
		}

	}
}

.slider-controls,
.slider-arrows{
	display: none;
}

.slider {
	position: absolute;
	top:0;
	left:0;
	right:0;
	bottom:0;
}

.slide {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	.img {
		background-position: center center;
		background-size: cover;
	}
}

.panel-1,
.panel-2,
.panel-3 {
	overflow: hidden;
	transition: transform 0.3s $ease-slider, opacity 0.3s $ease-slider 0.3s;
	
	.inactive & {
		z-index: 1;
	}

	.active & {
		z-index: 2;
	}

	.leaving & {
		z-index: 3 !important;
	}

	& > div {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		transform-origin: 0 50%;
		transition: transform 1s $ease-slider, opacity 0.4s $ease-slider;

		transform: translateX(0);

		.leaving & {
			opacity: 0;
		}
	}

	.img {
		position: absolute;
		width: 100%;
		height: 100%;
			
		transform-origin: 100% 0;

		.inactive & {
			transform: scale(1.1);
			transition: transform 0s $ease-slider;
		}

		.active &,
		.leaving & {
			transform: scale(1);
			transition: transform 1.4s $ease-slider;
		}
	}
}

.panel-1 {
	position: absolute;
	top: 0;
	left:0;
	right:0;
	height:120px;	

	& > div {
		transition-delay: 0s;
	}

	.img {
		top: 0;
		left: 0;

		.active &,
		.leaving & {
			transition-delay: 0s;
		}
	}
}

.panel-2 {
	position: absolute;
	width: percentage(1/2); 
	top: 120px;
	left: 0;
	bottom:0;
	width:330px;

	& > div {
		transition-delay: 0.2s;
	}

	.img {
		top: -120px;
		left: 0;

		.active &,
		.leaving & {
			transition-delay: 0.2s;
		}
	}
}

.panel-3 {
	position: absolute;
	bottom: 0;
	left: 330px;
	top:120px;
	right:0;
	
	& > div {
		transition-delay: 0.3s;
	}

	.img {
		top:-120px;
		left: -330px;

		.active &,
		.leaving & {
			transition-delay: 0.3s;
		}
	}
}

/* 
########      ###      ######    ####  ##    ##      ###     ########   ########   
##     ##    ## ##    ##    ##    ##   ###   ##     ## ##       ##      ##         
##     ##   ##   ##   ##          ##   ####  ##    ##   ##      ##      ##         
########   ##     ##  ##   ####   ##   ## ## ##   ##     ##     ##      ######     
##         #########  ##    ##    ##   ##  ####   #########     ##      ##         
##         ##     ##  ##    ##    ##   ##   ###   ##     ##     ##      ##         
##         ##     ##   ######    ####  ##    ##   ##     ##     ##      ########   
*/

.pagination {
	display: block;
	padding: 20px 40px 20px 0;
	float: right;
	position: relative;

	@include mq($from: desktop) {
		padding: 30px 50px 30px 0;
		margin-right: 20px;
	}

	@include mq($from: wide) {
		padding: 40px 50px 40px 0;
		margin-right: 30px;
	}

	&::before,
	&::after {
		content: "";
		display: block;
		height: 1px;
		position: absolute;
		top: 50%;
		margin-top: -1px;
		
		background-color: rgba($color-1, 0.6);
	} 

	&::before {
		width: 10px;
		left: -10px;
	}

	&::after {
		width: 20px;
		right: 20px;
	}


	li {
		display: inline-block;
		float: left;
		margin-left: 10px;

		@include mq($from: desktop) {
			margin-left: 15px;
		}
	}

	a {
		display: block;
		width: 10px;
		height: 26px;
		line-height: 24px;
		
		font-family: $font-title;
		color: $color-1;
		text-align: center;
		@include font-size(14);

		border: 1px solid transparent;

		transition: border-color 0.3s ease-in-out;

		@include mq($from: desktop) {
			@include font-size(18);
			width: 15px;
			height: 36px;
			line-height: 34px;
		}

		.no-touch &:hover,
		.no-touchevents &:hover,
		.no-touch &:focus,
		.no-touchevents &:focus,
		.touch &:active,
		.touchevents &:active {
			border-top-color: rgba($color-1, 0.6);
			border-bottom-color: rgba($color-1, 0.6);
		}

		&.current {
			width: 26px;
			border: 1px solid $color-1;
			border-radius: 50%;

			@include mq($from: desktop) {
				width: 36px;
			}
		}
	}
}

/* 
##     ##   #######   ##     ##  ########   
##     ##  ##     ##  ###   ###  ##         
##     ##  ##     ##  #### ####  ##         
#########  ##     ##  ## ### ##  ######     
##     ##  ##     ##  ##     ##  ##         
##     ##  ##     ##  ##     ##  ##         
##     ##   #######   ##     ##  ########   
*/

.image-bg--home-1 {
	.read-all {
		margin-top: 20px;
	}
}

.rotate-text {
	position: absolute;
	top: 0;
	bottom: 50px;
	right: 10px;
	opacity: 0.8;
	z-index: 1;
	
	border-right: 1px solid $white;
	border-right-color: rgba($white,.3);
	
	@include font-size(11);
	font-family: $font-alt;
	letter-spacing: 0.2em;
	font-weight: 400;
	text-transform: uppercase;

	transition: top 1s ease-out, bottom 1s ease-out;

	.in-view & {
		bottom: 20px;
	}

	.rotate-text-inner {
		display: block;
		position: absolute;
		left: 0;
		bottom: 0;

		white-space: nowrap;

		transform: rotate(-90deg);
		transform-origin: left bottom;
	}

	&.rotate-text--reverse{
		left: 10px;
		top: 50px;
		bottom: 0;
		right: auto;
		
		.theme-color-2 &{
			border-color: $color-1;
			border-color: rgba($color-1,.3);
		}
		
		.rotate-text-inner{
			transform: rotate(90deg);
			transform-origin: left bottom;
			bottom: 100%;
		}
	}

	@include mq($from: desktop) {
		bottom: 200px;
		right: 30px;

		.in-view & {
			bottom: 30px;
		}
		
		&.rotate-text--reverse{
			left: 30px;
			top: 200px;

			.in-view & {
				top: 30px;
			}
		}
	}
}

.home-list{

	.theme-color-2 &{
		float: right;
	}

	a {
		position: relative;
		@include font-size(30);
		font-family: $font-title;
		color: $white;
		line-height: 1em;
		min-height:60px;
		display: block;
		padding: 10px 0 0 80px;

		.no-touch &:hover, .no-touch &:focus, .touch &:active {
			span{				
				&:after{ right:-25px; }
			}
			.animation-icon-home{
				&:before,
				&:after{
					transform:translate(0,0);
					opacity: 1;
				}
				&:after{
					transform:translate(0,0);
				}
			}
		}

		.theme-color-2 & {		 	
		 	color: $color-1;			
			.no-touch &:hover, .no-touch &:focus, .touch &:active {

			}
		}
	}
	
	[class^="icon-"] {
		transition: background-color 0.4s ease-in-out, color 0.4s ease-in-out;
		@include font-size(18);
		position: absolute;
		top: 0;
		left: 0;
		display: block;
		border-radius: 50%;
		width: 50px;
		height: 50px;
		border: 1px solid $white;
		border-color: rgba($white, .7);
		text-align: center;
		line-height: 50px;

		.theme-color-2 & {
			border-color: $color-1;
			border-color: rgba($color-1, 0.7);
		}

		&:after{
			transition: right 0.2s ease-in-out;
			content:"";
			position: absolute;
			top: 50%;
			right: -20px;
			width: 25px;
			height: 1px;
			background-color: #fff;
			background-color: rgba(#fff,.7);

			.theme-color-2 &{
				background-color:$color-1;
				background-color: rgba($color-1,.7);
			}
		}
	}

	.animation-icon-home{
		position: absolute;
		top: 0;
		left: 0;	
		border-radius: 50%;
		width: 50px;
		height: 50px;
		overflow: hidden;

		&:after,
		&:before{
			transition: transform 0.5s $ease, opacity 0.4s $ease;
			content:"";
			display: block;
			position: absolute;
			top:0;
			left:0;
			width:100%;
			height:100%;
			border-radius: 50%;
			opacity: 1;
			background-color: lighten($color-1,5%);
		}

		&:before{
			transform:translate(0,-100%);
			.theme-color-2 &{
				background-color: darken($color-2,3%);
			}
		}
		&:after{
			transform:translate(0,100%);
			.theme-color-2 &{
				background-color: darken($color-2,3%);
			}
		}

	}

	@include mq($from: desktop) {
		a {
			@include font-size(34);
			padding: 25px 0 0 140px;
			min-height: 90px;
			margin:0 0 20px 0;

			.no-touch &:hover, .no-touch &:focus, .touch &:active {
				span:after{
					right: -50px;
				}
			}
		}

		[class^="icon-"]{
			@include font-size(30);
			width:80px;
			height:80px;
			line-height: 80px;
			&:after{
				width:50px;
				right:-40px;
			}
		}
		.animation-icon-home{
			width: 80px;
			height: 80px;
		}
	}

	@include mq($from: desktop) {

		li:first-of-type{ margin:0 0 20px 0; }

		a {
			@include font-size(34);
			padding:25px 0 0 140px;
			min-height: 90px;
			

			.no-touch &:hover, .no-touch &:focus, .touch &:active {
				span:after{
					right: -50px;
				}
			}
		}

		[class^="icon-"] {
			@include font-size(30);
			width:80px;
			height:80px;
			line-height: 80px;

			&:after {
				width:50px;
				right:-40px;
			}
		}
	}

	@include mq($from: wide) {

		li:first-of-type { margin: 0 0 50px 0; }
		
		a {
			min-height: 120px;
			padding: 40px 0 0 170px;
		}

		[class^="icon-"]{
			@include font-size(40);
			width:120px;
			height:120px;
			line-height: 120px;
			&:after{
				width:50px;
				right:-40px;
			}
		}
		.animation-icon-home{
			width: 120px;
			height: 120px;
		}
	}
}

/* 
##     ##  ####  ########   ########    #######    ######    
##     ##   ##   ##     ##  ##         ##     ##  ##    ##   
##     ##   ##   ##     ##  ##         ##     ##  ##         
##     ##   ##   ##     ##  ######     ##     ##   ######    
 ##   ##    ##   ##     ##  ##         ##     ##        ##   
  ## ##     ##   ##     ##  ##         ##     ##  ##    ##   
   ###     ####  ########   ########    #######    ######    
*/

.fluid-video {
	display: block;
	width: auto;
	height: auto;
	position: relative;
	width: 100%;
	height: 200px;
	margin: 0 auto;
	z-index: -100;
	overflow: hidden;

	@include mq($from: tablet) {
		height: 250px;
	}

	@include mq($from: desktop) {
		height: 350px;
	}

	@include mq($from: wide) {
		height: 450px;
	}

	@include mq($from: 1600px) {
		height: 550px;
	}

	.no-touch & {
		background: $color-bg;
	}

	.touch & {
		background: $color-2 url('#{$image-path}piscine-terrasse.jpg') center center no-repeat;
		background-size: cover;
	}
}

.fade-in-video {
	opacity: 0;
	transition: opacity 0.8s linear;

	&.is-playing {
		opacity: 1;
	}
}

video#bgvid {
	.no-touch & {
		position: absolute;

		top: 50%;
		left: 50%;
		min-width: 100%;
		min-height: 100%;

		transform: translateX(-50%) translateY(-50%);
	}

	.touch & {
		display: none;
	}
}

/* 
########    #######    #######   ########   ########   ########   
##         ##     ##  ##     ##     ##      ##         ##     ##  
##         ##     ##  ##     ##     ##      ##         ##     ##  
######     ##     ##  ##     ##     ##      ######     ########   
##         ##     ##  ##     ##     ##      ##         ##   ##    
##         ##     ##  ##     ##     ##      ##         ##    ##   
##          #######    #######      ##      ########   ##     ##  
*/

.footer{
	position: relative;
	background: transparent url('#{$image-path}piscine-footer.jpg') center center no-repeat;
	background-size: cover;
	&:before{
		content:"";
		position: absolute;
		top:0;
		left:0;
		bottom:0;
		right:0;
		background-color: $color-1;
		background-color: rgba($color-1,.9);
	}
}

.footer .inner{
	color:$white;
	padding:20px;

	.inner-content{
		opacity: .9;
	}

	@include mq($from: tablet) {
		padding:75px 20px;
	}

	@include mq($from: desktop) {
		padding:100px 20px;
	}

	a{
		transition: color 0.2s ease-in-out;
		color:$white;
		.no-touch &:hover, .no-touch &:focus, .touch &:active {
			color:lighten($color-1,35%);
		}
	}

}

.footer-column{
	@include mq($from: tablet) {
		float:left;
		width:50%;
		background-color: red;
	}
}

.footer-title{
	font-family: $font-alt;
	font-weight:400;
	border-bottom:1px solid $white;
	display: inline-block;
	text-transform: uppercase;
}

.footer-link{
	font-weight: 300;
	@include font-size(12);	

	li{
		position: relative;
		margin-bottom:10px;
		padding-left:20px;
		&:before{
			content:"";
			position: absolute;
			top:50%;
			left:0;
			width:14px;
			height:1px;
			background-color: $white;
		}
	}
}

.footer-social{
	transition: background-color 0.2s ease-in-out, 
				border-color 0.2s ease-in-out, 
				border-width 0.2s ease-in-out, 
				border-color 0.2s ease-in-out,
				color 0.2s ease-in-out;
	display: inline-block;
	border:1px solid $white;
	border-radius: 50%;
	width:36px;
	height:36px;
	line-height:36px;
	text-align: center;
	.no-touch &:hover, .no-touch &:focus, .touch &:active {
		color:$color-1 !important;
		background-color: $white;

		//&.icon-facebook{
		//	background-color: $color-facebook;
		//	border-color: lighten($color-facebook,10%);
		//}
		//&.icon-youtube{
		//	background-color: $color-youtube;
		//	border-color: lighten($color-youtube,10%);
		//}
	}
}

.footer .inner .btn--footer{
	@include font-size(11);
	transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
	display: inline-block;
	border:1px solid #fff;
	border:1px solid rgba(#fff,.5);
	padding:10px 20px;
	.no-touch &:hover, .no-touch &:focus, .touch &:active {
		background-color: #fff;
		color:$color-1;
	}

	[class^="icon-"]{
		@include font-size(14);
		display: inline-block;
		margin-right:5px;
	}

}

/* 
##    ##   ########   ##      ##  ######    
###   ##   ##         ##  ##  ## ##    ##   
####  ##   ##         ##  ##  ## ##         
## ## ##   ######     ##  ##  ##  ######    
##  ####   ##         ##  ##  ##       ##   
##   ###   ##         ##  ##  ## ##    ##   
##    ##   ########    ###  ###   ######    
 */

.title-lastnews{
	margin: -25px 0 0 10px;
	color: $black;
	@include mq($from: tablet) {
		margin: -25px 0 50px 10px;
	}
}

.list-news-inner{
	position: relative;
	padding:0 20px;
	cursor: pointer;

	&:before{
		transition: all 0.4s ease-in-out;
		content: "";
		position: absolute;
		z-index:0;
		top:-10px;
		left:-10px;
		right:10px;
		bottom:10px;
		border:2px solid $white;
		opacity: 0;
		visibility: hidden;
	}

	.no-touch &:hover, .no-touch &:focus, .touch &:active {
		&:before{
			top:0;
			left:5px;
			right:0;
			bottom:-10px;
			opacity: 1;
			visibility: visible;		
		}
	}

}

.date{
	@include font-size(18);
	position: relative;
	font-family: $font-title;
	color:$black;
	margin:0;

	&:before{
		content:"";
		display: block;
		position: absolute;
		top:15px;
		left:-20px;
		width:14px;
		height:1px;
		background:$black;
	}
}

.read {
	display: inline-block;
	margin-top: 20px;
	padding: 0 20px;
	line-height:40px;
	position: relative;
	z-index: 1;
	
	border: 2px solid $color-1;
	background-color: transparent;
	
	font-family: $font-alt;
	@include font-size(12);
	font-weight: 400;
	text-transform: uppercase;
	color: $color-1;

	transition: background-color 0.4s ease-in-out, color 0.4s ease-in-out;

	&:after {
		display: block;
		content: "";
		position: absolute;
		top: -1px;
		left: -1px;
		bottom: -1px;
		right: -1px;

		border: 1px solid $color-1;

		transition: transform 0.3s ease-in-out;
	}

	.no-touch &:hover, .no-touch &:focus, .touch &:active, .list-news-inner:hover & {
		&:after {
			transform: translate(4px, 4px);
		}
	}

	&.read--big {
		@include mq($from: desktop) {
			
			padding: 0 40px;
		}
	}
}

.read-all {
	display: block;
	padding: 0 90px 0 10px;
	margin-right: -10px;
	float: right;
	position: relative;
	line-height: 50px;
	overflow: hidden;
	
	font-family: $font-title;
	@include font-size(16);
	color: $black;


	&.read-all--no-float {
		float: none;
		display: inline-block;
		// text-align: center;
	}

	&::before,
	&::after {
		content: "";
		width: 30px;
		height: 2px;
		position: absolute;
		right: 50px;
		top: 50%;

		background: $black;
		background: rgba($black,.3);

		transform: translate(0, 0);
		transition: transform 0.4s ease-out 0.2s, width 0.3s ease-out;
	}

	&::before {
		width: 60px;
		transform: translate(-300px, 0);
	}

	.read-all__label {
		display: inline-block;
		white-space: nowrap;

		transform: translate(0, 0);
		transition: transform 0.3s ease-out;

		&.read-all__label--bigger {
			@include mq($from: tablet) {
				@include font-size(20);
			}

			@include mq($from: desktop) {
				@include font-size(24);
			}

		}

	}

	span[class^="icon-"] {
		display: inline-block;
		width: 50px;
		height: 50px;
		margin: -25px 0 0 0;
		position: absolute;
		top: 50%;
		right: 10px;
		line-height: 50px;
		z-index: 1;
		
		border: 1px solid $color-1;
		border-radius: 50%;
		
		color: $color-1;
		text-align: center;

		transform: rotate(-45deg);

		transition:
			background-color 0.3s ease-in-out,
			color 0.3s ease-in-out,
			border-color 0.7s $ease;

		&:before{
			display: block;
			transform: rotate(45deg) !important;
		}
	}

	.no-touch &:hover,
	.no-touchevents &:hover,
	.no-touch &:focus,
	.no-touchevents &:focus,
	.touch &:active,
	.touchevents &:active {
		&::before {
			width: 20px;
			transform: translate(0, 0);
		}

		&::after {
			transform: translate(200px, 0);
		}

		.read-all__label {
			transform: translate(10px, 0);

			
		}
	}

	@include mq($from: desktop) {

		&.read-all--bigger {
			height: 80px;
			padding: 0 130px 0 10px;
			line-height: 80px;


			&::before,
			&::after { right: 80px; }
			
			span[class^="icon-"] {
				margin-top: -40px;
				width: 80px;
				height: 80px;
				line-height: 80px;
			}
		}
	}

	[class="row-project"] & {
		@include mq($from: tablet) {
			position: absolute;
			bottom: 0;
			right: 0;
		}

		@include mq($until: desktop) {
			margin-bottom: 20px;
			margin-right: 20px;
		}

		@include mq($from: desktop) {
			margin-bottom: 20px;
		}

		// @include mq($from: wide) {
		// 	margin-bottom: 40px;
		// }
	}

	@include mq($from: desktop) {
		body.projet & {
			padding-right: 90px;
			right: 20px;
		}

		body.projet [class="row-project"]:nth-of-type(even) & {
			right: -25px;
		}

		body:not(.projet) [class="row-project"]:nth-of-type(even) & {
			right: -25px;
		}

		body:not(.projet) [class="row-project"]:nth-of-type(odd) & {
			left: -25px;
			right: auto;
			padding-left: 80px;
			padding-right: 0;
			
			&::before,
			&::after {
				left: 40px;
				right: auto;
			}

			.no-touch &:hover,
			.no-touchevents &:hover,
			.no-touch &:focus,
			.no-touchevents &:focus,
			.touch &:active,
			.touchevents &:active {
				&::before {
					width: 20px;
					transform: translate(0, 0);
				}

				&::after {
					transform: translate(200px, 0);
				}

				.read-all__label {
					transform: translate(-10px, 0);
					
				}
			}
			
			span { left: 0; }
		}
	}

	

	

	

	// [class="row-project"] & {

	// 	@include mq($from: tablet) {
	// 		position: absolute;
	// 		bottom: 0;
	// 		right: 0;
	// 	}

	// 	@include mq($until: desktop) {
	// 		margin-bottom: 20px;
	// 		margin-right: 20px;
	// 	}
	// }

	// @include mq($from: desktop) {
	// 	margin-bottom: 20px;

	// 	.projet &{
	// 		padding-right: 80px;
	// 		right: 20px;
	// 	}

	// 	body:not(.projet) [class="row-project"]:nth-of-type(even) &{
	// 		right: -25px;
	// 	}
	// 	body:not(.projet) [class="row-project"]:nth-of-type(odd) &{
	// 		left: -25px;
	// 		right: auto;
	// 		padding-left: 80px;
	// 		padding-right: 0;
			
	// 		&::after {
	// 			left: 40px;
	// 			right: auto;
	// 		}
	// 		span { left: 0; }
	// 	}
	// }

	// @include mq($from: wide) {
	// 	body:not(.projet) &{
	// 		@extend .read-all--bigger;
	// 	}
	// 	body:not(.projet) [class="row-project"]:nth-of-type(even) &{
	// 		right:-40px;
	// 	}
	// 	body:not(.projet) [class="row-project"]:nth-of-type(odd) &{
	// 		left:-40px;
	// 		padding-left: 120px;
	// 		&:after { left: 70px; }
	// 	}
	// }

	// @include mq($from: xwide) {
	// 	.home & { right: -120px; }
	// }

}



/* 
########   ########    #######         ##   ########   ########   
##     ##  ##     ##  ##     ##        ##   ##            ##      
##     ##  ##     ##  ##     ##        ##   ##            ##      
########   ########   ##     ##        ##   ######        ##      
##         ##   ##    ##     ##  ##    ##   ##            ##      
##         ##    ##   ##     ##  ##    ##   ##            ##      
##         ##     ##   #######    ######    ########      ##      
 */

// .projet-list{
// 	// margin:50px 0;
// 	// @include mq($from: tablet) {
// 	// 	margin:100px 0;
// 	// }
// 	// @include mq($from: desktop) {
// 	// 	margin:150px 0;
// 	// }

// 	.realisation &,
// 	.actualite & { margin: 0; }

// }

.row-project{
	@include clearfix;
	backface-visibility:hidden;
	overflow: hidden;
	.no-touch &:hover, .no-touch &:focus, .touch &:active {
		
		.box-text{
			.box-read{
				[class^="icon-"]{
					background-color: $black;
					color:$white;
				}
			}
		}

		.box-image-hover{
			&:before, 
			&:after{
				transform:translate(60%,60%) rotate(-45deg);
				opacity: 1;
			}
		}

		&:nth-of-type(even){
			.box-image-hover{
				&:before{
					transform:translate(-60%,60%) rotate(45deg);
				}
				&:after{
					transform:translate(-75%,25%) rotate(45deg);
				}
			}
		}

	}

	
		
	.no-touch .realisation &:hover, .no-touch .realisation &:focus, .touch .realisation &:active,
	.no-touch .renovation &:hover, .no-touch .renovation &:focus, .touch .renovation &:active {

		span[class="icon-arrow"]{
			color:lighten($color-1,20%);
		}

		&:nth-of-type(odd){
			.icon-arrow{
				border-top-color:lighten($color-1,20%);
				border-left-color:lighten($color-1,20%);
			}	
		}

		&:nth-of-type(even){
			.icon-arrow{
				border-bottom-color:lighten($color-1,20%);
				border-right-color:lighten($color-1,20%);
			}
		}

	}

}

.box-text{

	position: relative;
	z-index:4;

	&:after{
		transition: border-width 0.5s ease-in-out, background-color 0.5s ease-in-out;
		content:"";
		position: absolute;
		top:0;
		left:0;
		right:0;
		bottom:0;
		border:0 solid $color-2;
		z-index:-1;
		background-color: #fff;
	}
}

.box-title{
	@include font-size(26);
	line-height: 1.2em;
	background-color: $color-1;
	color:$white;
	font-family: $font-title;
	text-align: center;
	@include mq($until: tablet) {
		padding:30px 0;
	}
	@include mq($from: tablet) {
		@include font-size(36);	
		line-height: 1em;
	}

	.box-inner{
		transition: opacity 0.7s $ease;
		opacity: 0;
	}

}

.box-title-number{
	@include font-size(34);
	line-height: 1em;
	display: block;
	@include mq($from: tablet) {
		@include font-size(50);	
	}
}

.box-content {

	.title-lev2{ @include font-size(15); }

	p { 
		@include font-size(12); 
		line-height:1.6em;
	}

	padding: 40px;

	@include mq(tablet, desktop) {
		padding: 40px 150px 40px 40px;
	}

	.realisation .row-projet &,
	.actualite .row-projet & {
		@include mq($until: desktop) {
			padding-right: 100px !important;
			background-color: red;
		}
	}
	
	.realisation .row-project:nth-of-type(even) &,
	.actualite .row-project:nth-of-type(even) &  {	

		@include mq($from: desktop) {
			padding-right: 35%;
		}
		@include mq($from: xwide) {
			padding-right: 0;
			max-width: 420px;
		}
	}

	.realisation .row-project:nth-of-type(odd) &,
	.actualite .row-project:nth-of-type(odd) &  {
		@include mq($from: desktop) {
			padding-left: 35%;
		}
		@include mq($from: xwide) {
			padding-left: 0;
			max-width: 420px;
		}
	}


}

.location{
	position: relative;
	font-family: $font-title;
	@include font-size(20);
	margin-bottom:10px;
	display: block;
	&:before{
		content:"";
		position: absolute;
		top:50%;
		left:-20px;
		width:15px;
		height:1px;
		background-color: $black;
	}
}

.box-image{
	position: relative;
	background:transparent center center no-repeat;
	background-size: cover;
	overflow: hidden;

	@include mq($until: tablet) {
		&:after{
			padding-top: 45%;
		}
	}

	&.box-image--avant-projet{
		background-image: url('#{$image-path}projet/avant-projet.jpg');
	}
	&.box-image--votre-piscine{
		background-image: url('#{$image-path}projet/votre-piscine.jpg');
	}
	&.box-image--amenagement-piscine{
		background-image: url('#{$image-path}projet/amenagement-piscine.jpg');
	}
	&.box-image--pergolas{
		background-image: url('#{$image-path}projet/equipement.jpg');
	}
	&.box-image--mobilier {
		background-image: url('#{$image-path}projet/mobilier.jpg');
	}
	&.box-image--sav-piscine{
		background-image: url('#{$image-path}projet/sav-piscine.jpg');
	}
	&.box-image--accessoire{
		background-image: url('#{$image-path}projet/accessoire.jpg');
	}

}

.box-image-hover{

	position: absolute;
	z-index: 1;
	top:0;
	left:0;
	bottom:0;
	right:0;
	overflow: hidden;

	&:before{
		transition: transform 0.7s $ease, opacity 0.5s $ease;
		content:"";
		position: absolute;
		z-index:1;
		top:0;
		left:0;
		right:0;
		bottom:0;
		background-color:rgba($color-1,.6);
		transform-origin:50%;
		transform:translate(-25%,-25%) rotate(-45deg);
		opacity: 0;
	}

	&:after{
		transition: transform 0.7s $ease, opacity 0.5s $ease;
		content:"";
		position: absolute;
		z-index:3;
		top:0;
		left:0;
		right:0;
		bottom:0;
		background-color:rgba($color-1,1);
		transform-origin:50%;
		transform:translate(25%,25%) rotate(-45deg);
		opacity: 0;
	}

	// @include mq($from: desktop) {
		.row-project:nth-of-type(even) &{
			&:before{
				transform:translate(25%,-25%) rotate(45deg);
			}
			&:after{
				transform:translate(-25%,25%) rotate(45deg);
			}
		}
	// }

}

/* 
########   ########   ########      ###     ####  ##              ########   ########      ###     
##     ##  ##            ##        ## ##     ##   ##              ##     ##  ##           ## ##    
##     ##  ##            ##       ##   ##    ##   ##              ##     ##  ##          ##   ##   
##     ##  ######        ##      ##     ##   ##   ##              ########   ######     ##     ##  
##     ##  ##            ##      #########   ##   ##              ##   ##    ##         #########  
##     ##  ##            ##      ##     ##   ##   ##              ##    ##   ##         ##     ##  
########   ########      ##      ##     ##  ####  ########        ##     ##  ########   ##     ##  
 */

.detail {

	li{

		&.wookmark-inactive {
			visibility: hidden;
			opacity: 0;
		}

		img{
			width:100%;
			height:auto;
		}
	}

}

.mix-inner{
	padding: 40px;
	@include mq($from: desktop) {
		padding:75px 50px;
		margin:0 auto;
	}
	@include mq($from: wide) {
		padding:150px 0;
		max-width:420px;
	}
}

.detail{
	@include clearfix;
	position: relative;
}

.photoswipe {

	position: relative;
	cursor: pointer;
	overflow: hidden;

	.pinterest{
		transition: background-color 0.5s ease-in-out;
		position: absolute;
		bottom:0px;
		right:0px;
		width:40px;
		height:40px;
		line-height:40px;
		text-align:center;
		@include font-size(20);
		background-color: transparent;

		.no-touch &:hover, .no-touch &:focus, .touch &:active {
			background-color: #fff;
		}
	}

	&:after{
		transition: left 0.4s ease-in-out;
		@extend %icon;
		content: "\e908";
		position: absolute;
		bottom:0;
		left:-40px;
		display: block;
		width:40px;
		height:40px;
		line-height: 40px;
		text-align: center;
		background-color: $color-1;
		color:$white;
		@include font-size(26);
	}

	p{
		transition: all 0.4s ease-in-out;
		@include center;
		width:100%;
		text-align: center;
		color:$white;
		text-transform: uppercase;
		font-family: $font-alt;
		font-weight: 700;
		padding-top:50px;
		opacity: 0;
		&:after{
			content: "";
			display: block;
			width:50px;
			height:1px;
			margin:0 auto;
			background-color: $white;
			background-color: rgba($white,.5);
			margin-top:10px;
		}
	}

	.no-touch &:hover, .no-touch &:focus, .touch &:active {
		p{
			opacity: 1;
			padding-top:0;
		}
		&:after{
			left:0;
		}
	}

}

.spinner{
	z-index:40;
	position: fixed;
	top:0;
	left:0;
	width:100%;
	height:100%;
	text-align: center;
	color:$color-1;

	.spinner-inner{
		background-color: #fff;
		background-color: rgba(255, 255, 255, 0.8);		
		padding:50px 0;
	}

	.spinner-content{
		max-width:350px;
		margin:0 auto;		
	}

	p{
		margin:0;
		padding:0;
	}

	.progress-bar{
		transition: width 0.2s ease-in-out;
		width:0;
		height:3px;
		background-color: $color-1;
	}

}

.list-intervention {
	margin-bottom: 20px;
	
	li {
		display: inline-block;
		position: relative;
		margin-right: 20px;

		.projet-list &{
			margin-right: 10px;
		}
		
		[class^="icon-"] {
			@include font-size(40);
			color: $color-1;
			transition: color 0.3s ease-out;

			.projet-list &{
				@include font-size(30);
			}
		}

		.no-touch &:hover,
		.no-touchevents &:hover,
		.no-touch &:focus,
		.no-touchevents &:focus,
		.touch &:active,
		.touchevents &:active {
			[class^="icon-"] {
				color: lighten($color-1, 10%);
			}

			.list-intervention__tooltip {
				opacity: 1;
				visibility: visible;
				transform: translate(0, 0);
			}
		}
	}
}

.list-intervention__tooltip {

	display: inline-block;
	// height: 20px;
	// line-height: 20px;
	min-width: 150px;
	padding: 5px 10px;
	position: absolute;
	top: 50%;
	left: 50%;
	margin-top: -11px;
	opacity: 0;
	z-index: 2;
	visibility: hidden;
	pointer-events: none;

	background-color: $color-1;

	color: $white;
	@include font-size(12);
	font-family: $font-title;

	transform: translate(-20px, 0);
	transition:
		transform 0.3s ease-out,
		visibility 0.3s ease-out,
		opacity 0.3s ease-out;

}

/* 
 ######     #######    ######    ####     ###     ##         
##    ##   ##     ##  ##    ##    ##     ## ##    ##         
##         ##     ##  ##          ##    ##   ##   ##         
 ######    ##     ##  ##          ##   ##     ##  ##         
      ##   ##     ##  ##          ##   #########  ##         
##    ##   ##     ##  ##    ##    ##   ##     ##  ##         
 ######     #######    ######    ####  ##     ##  ########   
 */
/* 
 ######    ##     ##     ###     ########   ########   
##    ##   ##     ##    ## ##    ##     ##  ##         
##         ##     ##   ##   ##   ##     ##  ##         
 ######    #########  ##     ##  ########   ######     
      ##   ##     ##  #########  ##   ##    ##         
##    ##   ##     ##  ##     ##  ##    ##   ##         
 ######    ##     ##  ##     ##  ##     ##  ########   
 */

.social-share{
	text-align: right;
	li{
		display: inline-block;
		span{
			transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
			display: inline-block;
			background-color: #efefef;
			width:40px;
			height:40px;
			line-height:40px;
			text-align: center;
			border-radius:50%;
			color:#333;
			@include font-size(18);

			.no-touch &:hover, .no-touch &:focus, .touch &:active {
				color:$white;
				&.icon-facebook{ background-color: $color-facebook; }
				&.icon-twitter{ background-color: $color-twitter; }
				&.icon-google-plus{ background-color: $color-google-plus; }
			}
		}
	}

}