/* 
 ######    ########   ####  ########   
##    ##   ##     ##   ##   ##     ##  
##         ##     ##   ##   ##     ##  
##   ####  ########    ##   ##     ##  
##    ##   ##   ##     ##   ##     ##  
##    ##   ##    ##    ##   ##     ##  
 ######    ##     ##  ####  ########   
 */

.row {
	display: block;
	@include clearfix;
	// margin: -$grid-gutter-base;

	@include mq($from: tablet) {
		margin: -$grid-gutter-tablet;
	}

	@include mq($from: desktop) {
		margin: -$grid-gutter-desktop;
	}

	@include mq($from: wide) {
		margin: -$grid-gutter-wide;
	}

	&.add-vertical-padding {
		@include mq($until: tablet) {
			padding-top: $grid-gutter-base;
			padding-bottom: $grid-gutter-base;
		}
	}
}

.col-1-2,
.col-2-2,
.col-1-3,
.col-2-3,
.col-3-3,
.col-1-4,
.col-2-4,
.col-3-4,
.col-4-4 {
	position: relative;
	display: block;
	width: 100%;
	padding: $grid-gutter-base;
	float: left;

	@include mq($from: tablet) {
		padding: $grid-gutter-tablet;
	}

	@include mq($from: desktop) {
		padding: $grid-gutter-desktop;
	}

	@include mq($from: wide) {
		padding: $grid-gutter-wide;
	}

	.projet-list & {
		padding: 0;
	}

	.row--nopadding & {
		padding: 0;
	}

}

.col-split-5{
	position: relative;
	display: block;
	width: 100%;
	float: left;
}

/* 
########      ###     ########   ##         ########   ########   
   ##        ## ##    ##     ##  ##         ##            ##      
   ##       ##   ##   ##     ##  ##         ##            ##      
   ##      ##     ##  ########   ##         ######        ##      
   ##      #########  ##     ##  ##         ##            ##      
   ##      ##     ##  ##     ##  ##         ##            ##      
   ##      ##     ##  ########   ########   ########      ##      
 */

.offset-1-4--form-tablet{
	@include mq($from: tablet) {
		margin-left:percentage(1/4);
	}
}

.col-1-2--from-tablet{
	@extend .col-1-2;
	@include mq($from: tablet) {
		width:percentage(1/2);
	}
}

.col-1-3--from-tablet{
	@extend .col-1-3;
	@include mq($from: tablet) {
		width:percentage(1/3);
	}
}

.col-2-3--from-tablet{
	@extend .col-2-3;
	@include mq($from: tablet) {
		width:percentage(2/3);
	}
}

/* 
########   ########    ######    ##    ##   ########    #######   ########   
##     ##  ##         ##    ##   ##   ##       ##      ##     ##  ##     ##  
##     ##  ##         ##         ##  ##        ##      ##     ##  ##     ##  
##     ##  ######      ######    #####         ##      ##     ##  ########   
##     ##  ##               ##   ##  ##        ##      ##     ##  ##         
##     ##  ##         ##    ##   ##   ##       ##      ##     ##  ##         
########   ########    ######    ##    ##      ##       #######   ##         
 */

.col-1-2--from-desktop{
	@extend .col-1-2;
	@include mq($from: desktop) {
		width:percentage(1/2);
	}
}

.col-1-3--from-desktop{
	@extend .col-1-3;
	@include mq($from: desktop) {
		width:percentage(1/3);
	}
}

.col-2-3--from-desktop{
	@extend .col-2-3;
	@include mq($from: desktop) {
		width:percentage(2/3);
	}
}

.col-1-4--from-desktop{
	@extend .col-1-4;
	@include mq($from: desktop) {
		width:percentage(1/4);
	}
}

.col-2-4--from-desktop{
	@extend .col-2-4;
	@include mq($from: desktop) {
		width:percentage(2/4);
	}
}

.col-1-5--from-desktop{
	@extend .col-split-5;
	@include mq($from: desktop) {
		width:percentage(1/5);
	}
}

.col-2-5--from-desktop{
	@extend .col-split-5;
	@include mq($from: desktop) {
		width:percentage(2/5);
	}
}

.col-3-5--from-desktop{
	@extend .col-split-5;
	@include mq($from: desktop) {
		width:percentage(3/5);
	}
}

/* 
##      ## ####  ########   ########   
##  ##  ##  ##   ##     ##  ##         
##  ##  ##  ##   ##     ##  ##         
##  ##  ##  ##   ##     ##  ######     
##  ##  ##  ##   ##     ##  ##         
##  ##  ##  ##   ##     ##  ##         
 ###  ###  ####  ########   ########   
 */

.col-1-3--from-wide{
	@extend .col-1-3;
	@include mq($from: wide) {
		width:percentage(1/3);
	}
}

.col-1-4--from-wide{
	@extend .col-1-4;
	@include mq($from: wide) {
		width:percentage(1/4);
	}
}

.col-2-4--from-wide{
	@extend .col-2-4;
	@include mq($from: wide) {
		width:percentage(2/4);
	}
}

.col-2-5--from-xwide{
	@extend .col-split-5;
	@include mq($from: xwide) {
		width:percentage(2/5);
	}
}

.col-1-5--from-xwide{
	@extend .col-split-5;
	@include mq($from: xwide) {
		width:percentage(1/5);
	}
}

.reverse--from-tablet{
	@include mq($from: tablet) {
		float:right;
	}
}

.reverse--from-desktop{
	@include mq($from: desktop) {
		float:right;
	}
}