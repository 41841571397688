.hidden { display: none !important;  }

.clear { clear: both; }

.align-left   { text-align: left; }
.align-center { text-align: center; }
.align-right  { text-align: right; }

.nowrap{ white-space: nowrap; }

.absolute-child {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

@mixin center($width: null, $height: null) {
	position: absolute;
	top: 50%;
	left: 50%;

	@if not $width and not $height {
		transform: translate(-50%, -50%);
	} @else if $width and $height {
		width: $width;
		height: $height;
		margin: -($width / 2) #{0 0} -($height / 2);
	} @else if not $height {
		width: $width;
		margin-left: -($width / 2);
		transform: translateY(-50%);
	} @else {
		height: $height;
		margin-top: -($height / 2);
		transform: translateX(-50%);
	}
}

@mixin vertical-align() {
	display: table;
	width: 100%;
	height: 100%;
}

@mixin vertical-align-inner() {
	display: table-cell;
	vertical-align: middle;
}

.vertical-align {
	@include vertical-align();
}

.vertical-align-inner {
	@include vertical-align-inner();
}

.to-tablet,
.until-tablet {
	@include mq($from: tablet) {
		display: none;
	}
}

.to-desktop,
.until-desktop {
	@include mq($from: desktop) {
		display: none;
	}
}

.to-wide,
.until-wide {
	@include mq($from: wide) {
		display: none;
	}
}

.to-xwide,
.until-xwide {
	@include mq($from: xwide) {
		display: none;
	}
}

.from-tablet {
	@include mq($until: tablet) {
		display: none;
	}
}

.from-desktop {
	@include mq($until: desktop) {
		display: none;
	}
}

.from-wide {
	@include mq($until: wide) {
		display: none;
	}
}

.from-xwide {
	@include mq($until: xwide) {
		display: none !important;
	}
}

%better-icon,
%icon {
	font-family: $font-icon;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

#__bs_notify__ {
	width: 170px !important;
	right: 50% !important;
	padding: 10px !important;
	margin-right: -85px !important;
	background-color: rgba(#000, 0.3) !important;
	border-radius: 0 !important;
	font-size: 12px !important;
}

.box-square{

	display: block;

	@include mq($from: tablet) {
		&:after{
			content:"";
			display: block;
			padding-top:100%;
		}	
	}

}

.box-rectangle{

	display: block;

	&:after{
		content:"";
		display: block;
		padding-top:25%;
	}

	@include mq($from: tablet) {
		&:after{
			padding-top:50%;
		}
	}

}

/* 
########      ###     ########   ##         ########   ########   
   ##        ## ##    ##     ##  ##         ##            ##      
   ##       ##   ##   ##     ##  ##         ##            ##      
   ##      ##     ##  ########   ##         ######        ##      
   ##      #########  ##     ##  ##         ##            ##      
   ##      ##     ##  ##     ##  ##         ##            ##      
   ##      ##     ##  ########   ########   ########      ##      
 */

.box-rectangle--from-tablet{
	@include mq($from: tablet) {
		&:after{
			content:"";
			display: block;
			padding-top:50%;
		}
		.box-inner{ @include center; }
	}
}

.box-square--from-tablet{
	@include mq($from: tablet) {
		&:after{
			content:"";
			display: block;
			padding-top:100%;
		}
		.box-inner{ @include center; }
	}
}

/* 
########   ########    ######    ##    ##   ########    #######   ########   
##     ##  ##         ##    ##   ##   ##       ##      ##     ##  ##     ##  
##     ##  ##         ##         ##  ##        ##      ##     ##  ##     ##  
##     ##  ######      ######    #####         ##      ##     ##  ########   
##     ##  ##               ##   ##  ##        ##      ##     ##  ##         
##     ##  ##         ##    ##   ##   ##       ##      ##     ##  ##         
########   ########    ######    ##    ##      ##       #######   ##         
 */

.box-square--from-desktop{
	@include mq($from: desktop) {
		&:after{
			content:"";
			display: block;
			padding-top:100%;
		}
		.box-inner{ @include center; }
	}
}

.box-rectangle--from-desktop{
	@include mq($from: desktop) {
		&:after{
			content:"";
			display: block;
			padding-top:50%;
		}
		.box-inner{ @include center; }
	}
}

/* 
##      ## ####  ########   ########   
##  ##  ##  ##   ##     ##  ##         
##  ##  ##  ##   ##     ##  ##         
##  ##  ##  ##   ##     ##  ######     
##  ##  ##  ##   ##     ##  ##         
##  ##  ##  ##   ##     ##  ##         
 ###  ###  ####  ########   ########   
 */

.box-rectangle--from-wide{
	@include mq($from: wide) {
		&:after{
			content:"";
			display: block;
			padding-top:50%;
		}
		.box-inner{ @include center; }
	}
}

.box-rectangle--from-xwide{
	@include mq($from: xwide) {
		&:after{
			content:"";
			display: block;
			padding-top:50%;
		}
		.box-inner{ @include center; }
	}
}

.box-square--from-wide{
	@include mq($from: wide) {
		&:after{
			content:"";
			display: block;
			padding-top:100%;
		}
		.box-inner{ @include center; }
	}
}

/* 
##     ##  ##      ## ####  ########   ########   
 ##   ##   ##  ##  ##  ##   ##     ##  ##         
  ## ##    ##  ##  ##  ##   ##     ##  ##         
   ###     ##  ##  ##  ##   ##     ##  ######     
  ## ##    ##  ##  ##  ##   ##     ##  ##         
 ##   ##   ##  ##  ##  ##   ##     ##  ##         
##     ##   ###  ###  ####  ########   ########   
 */

.box-square--from-xwide{
	@include mq($from: xwide) {
		&:after{
			content:"";
			display: block;
			padding-top:100%;
		}
		.box-inner{ @include center; }
	}
}

.box-inner{
	width:100%;
	padding:20px;
}