.selectric {
	border: none;
	background: #fff;	
	z-index: 1000;

	border-bottom: 1px solid $color-1;
	border-bottom-color: rgba($color-1, 0.6) !important;

	.label {
		padding: 10px;
		margin: 0;
		font-weight: 700;
	}

	.button {
		top: 2px;

		transition: transform 0.2s linear;

		.selectric-open & {
			transform: rotate(180deg);
		}
	}
}

.selectric-items {
	background-color: $color-1;
	border: none;
	margin: 0;
	padding: 0;
}

.selectric-items li {
	display: block;
	padding: 8px 10px;
	border: none;

	border-bottom: 1px solid lighten($color-1, 2%);

	color: $white;
	color: rgba($white, 0.85);

	transition:
		background-color 0.2s ease-out,
		color 0.2s ease-out;
	
	&.selected {
		background-color: lighten($color-1, 5%);
		color: $white;
	}

	.no-touch &:hover,
	.no-touchevents &:hover,
	.no-touch &:focus,
	.no-touchevents &:focus,
	.touch &:active,
	.touchevents &:active {
		background-color: lighten($color-1, 2%);
		color: $white;
	}
	
}