html { font-size: 62.5%; }

$font-base:  'Open Sans', sans-serif;
$font-title: "butlerlight", serif;
$font-alt:   "brandon", sans-serif;
$font-icon:  'piscines-concept';
$image-path: '/images/';

$ease : cubic-bezier(0.86, 0, 0.07, 1);
$ease-slider : cubic-bezier(.215, .61, .355, 1);

$color-1: #4c5267; // violet
$color-2: #f0f1f4; // gris light
$color-3: #6986c2; // bleu logo

$color-bg:          #fff;
$color-text:        #000;
$color-text-light:  #fff;

$color-cancel: #bc2121;
$color-warning: red;

$color-link: $color-1;
$color-link-hover: #baa264;

$color-border: #ddd;

$color-facebook:    #3b5998;
$color-youtube:     #ff0000;
$color-twitter:     #00c8fa;
$color-pinterest:   #cb2027;
$color-linkedin:    #0073b2;
$color-google-plus: #dd4b39;

$white: #fff;
$black: #000;

$grid-gutter-base:    20px / 2;
$grid-gutter-tablet:  20px / 2;
$grid-gutter-desktop: 40px / 2;
$grid-gutter-wide:    80px / 2;